import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import userRouter from './modules/user'
import yijiesuanRouter from './modules/yijiesuan'
import jiluchaxunRouter from './modules/jiluchaxun'

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },
    // {
    //     path: '/weijiesuan',
    //     component: () => import('@/views/weijiesuan/index'),
    //     hidden: true
    // },
    // {
    //     path: '/',
    //     component: () => import('@/components/HelloWorld'),
    //     hidden: true
    // },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },

    {
        path: '/',
        component: Layout,
        redirect: '/weijiesuan',
        children: [{
            path: 'weijiesuan',
            name: 'weijiesuan',
            component: () => import('@/views/weijiesuan/index'),
            meta: { title: '未结算', icon: 'dashboard' }
        },]
    },

    {
        path: '/weijiesuan',
        component: Layout,
        redirect: 'weijiesuan',
        children: [{
            path: '',
            name: 'weijiesuan',
            component: () => import('@/views/weijiesuan/index'),
            meta: { title: '未结算', icon: 'dashboard' }
        },]
    },
    userRouter,
    yijiesuanRouter,
    jiluchaxunRouter,


    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()
export default router


