import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import store from '@/store'

// 路由白名单
const whiteList = ['/login','/404']


// 前置守卫
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  
  if(localStorage.getItem('token')){
    // 存在token
    if(to.path === '/login'){
      // 跳转到首页
      next('/')
      NProgress.done()
    } else {
      // 判断是否获取过资料
    //   if (!store.getters.userId) {
    //     await store.dispatch('user/getUserInfo')
    //   }
      next()
    }
  } else {
    // 不存在token
    if(whiteList.includes(to.path)){
      // 在白名单中
      next()
    } else {
      // 不在白名单中
      next('/login')
      NProgress.done()
    }
  }

  // 判断是否有标题
  if (to.meta.title) { 
    document.title = to.meta.title + " - 抓鸡记账系统";
  }
})


// 后置守卫
router.afterEach(()=>{
  // 关闭进度条
  NProgress.done()
})