<template>
  <div id="app">
    <meta name="apple-mobile-web-app-capable" content="yes">
    <!-- <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"> -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body, html {  
  margin: 0; 
  padding: 0;
  width: 100%;  
  overflow-x: hidden;  
}



.myBox{
  min-width: 80% !important;
  margin-top:  80px;
}


</style>
