<template>
  <div>
    <div class="daohang">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo zhiti"
        mode="horizontal"
        @select="handleSelect"
        background-color="#0093E9"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="/weijiesuan">未结算</el-menu-item>
        <el-menu-item index="/yijiesuan">已结算</el-menu-item>
        <el-menu-item index="/user">人员管理</el-menu-item>
        <el-menu-item index="/jiluchaxun">记录查询</el-menu-item>
      </el-menu>
    </div>
    <div style="height: 60px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/weijiesuan",
    };
  },
  created() {
    // 生命周期
    this.activeIndex = this.$route.path;
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$router.push(key);
    },
  },
};
</script>

<style scoped>
.daohang {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 666;
}

.el-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 0px 13px;
  cursor: pointer;
  width: 25%;
}
</style>