<template>
  <div class="app-wrapper">
    <div class="main-container">
      <navbar/>
      <!-- <div></div> -->
      <!-- <br> -->
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, AppMain } from "./components";
export default {
  name: "Layout",
  components: {
    Navbar,
    AppMain,
  },
};
</script>

